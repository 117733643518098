define([
    'santa-components',
    'socialCommon',
    'skins',
    'facebookLikeBox/skins/skins.json',
    'componentsCore'
], function (santaComponents, socialCommon, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const compHeight = {SIMPLE: 130, FACES: 214, STREAM: 575, MAX: 2000};

    function getHeights(props) {
        const showFaces = props.compData.showFaces,
            showStream = props.compData.showStream,
            currentHeight = props.style.height || 0;
        const heights = {};

        if (!showStream && showFaces) {
            heights.minHeight = heights.maxHeight = heights.currentHeight = compHeight.FACES;
        } else if (showStream) {
            heights.minHeight = compHeight.STREAM;
            heights.maxHeight = compHeight.MAX;
            heights.currentHeight = currentHeight < heights.minHeight ? heights.minHeight : currentHeight;
        } else {
            heights.minHeight = heights.maxHeight = heights.currentHeight = compHeight.SIMPLE;
        }
        return heights;
    }

    const facebookLikeBox = {
        displayName: 'FacebookLikeBox',
        mixins: [componentsCore.mixins.skinBasedComp, socialCommon.facebookComponentMixin],

        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired
        },

        getInitialState() {
            return {
                ready: true
            };
        },

        getHref() {
            return `http://www.facebook.com/${this.props.compData && this.props.compData.facebookPageId}`;
        },

        getSkinProperties() {
            const data = this.props.compData,
                compStyle = this.props.style,
                heights = getHeights(this.props),
                href = this.getHref();

            return {
                '': {
                    children: santaComponents.utils.createReactElement('div', {
                        className: 'fb-page',
                        'data-href': href,
                        'data-height': heights.currentHeight,
                        'data-width': compStyle.width || 280,
                        'data-hide-cover': !data.showHeader,
                        'data-show-posts': data.showStream,
                        'data-show-facepile': data.showFaces,
                        'data-adapt-container-width': true,
                        // hack- fb sdk does not send the right container_width without it
                        key: `${compStyle.width}${compStyle.height}`
                    }),
                    style: {
                        minWidth: 280,
                        maxWidth: 500,
                        minHeight: heights.minHeight,
                        maxHeight: heights.maxHeight
                    }
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox', facebookLikeBox);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return facebookLikeBox;
});
