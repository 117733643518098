(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin'] = {
  "react": [
    [
      "div",
      "likeboxContainer",
      [],
      {},
      [
        "iframe",
        "iframe",
        [],
        {
          "allowtransparency": "true",
          "scrolling": "auto"
        }
      ]
    ]
  ],
  "css": {
    "%likeboxContainer": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-state~=\"bgStyle-light\"] %likeboxContainer": "background-color:#fff;",
    "%[data-state~=\"bgStyle-dark\"] %likeboxContainer": "background-color:#000;",
    "%[data-state~=\"bgStyle-noBg\"] %likeboxContainer": "background-color:transparent;",
    "%iframe": "border:0;box-sizing:border-box;width:100%;height:100%;"
  }
}

        return skins;
    }));